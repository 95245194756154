<template>
    <header class="c-work-hero" >
        <div class="o-container">
            <div class="o-grid -col-12 -gutter">
                <div class="u-gc-1/13 u-gc-2/12@from-small">
                    <p class="c-work-hero_index">
                        <span class="c-text-work -index-italic"><i>F</i></span>
                        <span class="c-text-work -index">-{{ uPad(index, 10) }}</span>
                    </p>

                    <div class="o-padding -padding-tiny-top">
                        <h1
                            class="c-work-hero_title || c-heading-work -title"
                            v-html="title"
                        />
                    </div>
                    <div class="c-work-hero_cover">
                        <div v-if="imageSrc" class="c-figure">
                            <div class="c-figure_inner || o-lazy">
                                <img 
                                    class="c-figure_image || o-lazy_image" :class="{'-lazy-loaded': imageLoaded}"
                                    :src="imageSrc"
                                    alt="tmp"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions } from 'vuex';
import { getContextImageUrl } from "../../core/images/contextImage";

export default {
    name: "WorkHero",
    props: {
        title: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            default: 0,
        },
        cover: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            imageSrc: '',
            imageLoaded: false
        };
    },
    created() {
        this.imageSrc = getContextImageUrl(this.cover, 'cover')
    },
    mounted() {
        this.$nextTick(() => {
            this.forceScrollUpdate()
        })

        setTimeout(() => {
            this.imageLoaded = true
        }, 100)
    },
    methods: {
        ...mapActions('main', ['forceScrollUpdate']),

        uPad(number, base) {
            return number < base ? "0" + number.toString() : number.toString();
        },
    },
};
</script>