<template>
    <div class="c-work-number" >
        <div class="o-container || o-padding -padding-wide-top">
            <div class="c-work-number_inner">
                <div class="c-work-number_col || u-gc-1/13 u-gc-1/8@from-small">
                    <p v-if="number" class="c-work-number_number || c-text-work -number">{{ number }}</p>
                </div>
                <div class="c-work-number_col || u-gc-1/13 u-gc-8/13@from-small">
                    <p v-if="description" class="c-work-number_label || c-text-work -number-label" v-html="description" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WorkNumber",
    components: {},
    props: {
        number: {
            type: Number,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>