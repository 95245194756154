<template>
    <article class="c-page -work">
        <div class="o-scroll" ref="scroll">
            <template v-if="work">
                <work-hero :title="work.title" :index="work.index" :cover="work.cover" />

                <work-timer v-if="work.releasedAt" :date="work.releasedAt" />

                <work-intro v-if="work.introduction || work.informations" :introduction="work.introduction" :items="work.informations" />

                <template v-for="(component, index) in work.components" :key="index">
                    <template v-if="component._type === 'projectBlockRail'">
                        <work-rail v-if="component.message" :message="component.message" />
                    </template>
                    <template v-else-if="component._type === 'projectBlockVideo'">
                        <work-video-trigger :vimeo-id="component.videoId" :cover="component.videoCover" />
                    </template>
                    <template v-else-if="component._type === 'projectBlockColumn'">
                        <work-column :description="component.description" :images="component.images" :is-reversed="component.isColumnReversed" />
                    </template>
                    <template v-else-if="component._type === 'projectBlockComposition'">
                        <work-composition :description="component.description" :images="component.images" :composition-index="component.compositionIndex" />
                    </template>
                    <template v-else-if="component._type === 'projectBlockText'">
                        <work-text :text="component.text" />
                    </template>
                    <template v-else-if="component._type === 'projectBlockNumber'">
                        <work-number :number="component.number" :description="component.description" />
                    </template>
                    <template v-else-if="component._type === 'projectBlockCarousel'">
                        <work-carousel :images="component.images" />
                    </template>
                    <template v-else-if="component._type === 'projectBlockImage'">
                        <work-image v-if="component.image" :image="component.image"/>
                    </template>
                </template>
                
                <work-credits v-if="work.credentials || work.legals" :credentials="work.credentials" :legals="work.legals" />

                <work-footer v-if="nextWork" :title="nextWork.title" :index="nextWork.index" :cover="nextWork.cover" :slug="nextWork.slug.current"/>
            </template>
        </div>

        <template v-if="work">
            <template v-for="(component, index) in work.components" :key="index">
                <template v-if="component._type === 'projectBlockVideo'">
                    <work-video-modal :vimeo-id="component.videoId" />
                </template>
            </template>
        </template>
    </article>
</template>

<script>
import WorkCarouselVue from "../components/work/WorkCarousel.vue";
import WorkColumnVue from "../components/work/WorkColumn.vue";
import WorkCompositionVue from "../components/work/WorkComposition.vue";
import WorkCreditsVue from "../components/work/WorkCredits.vue";
import WorkFooterVue from "../components/work/WorkFooter.vue";
import WorkHeroVue from "../components/work/WorkHero.vue";
import WorkIntroVue from "../components/work/WorkIntro.vue";
import WorkNumberVue from "../components/work/WorkNumber.vue";
import WorkRailVue from "../components/work/WorkRail.vue";
import WorkTextVue from "../components/work/WorkText.vue";
import WorkTimerVue from "../components/work/WorkTimer.vue";
import WorkVideoModalVue from "../components/work/WorkVideoModal.vue";
import WorkVideoTriggerVue from "../components/work/WorkVideoTrigger.vue";
import WorkImageVue from '../components/work/WorkImage.vue';

import locomotiveScrollMixin from "../mixins/locomotiveScrollMixin"; 
import cursorMixin from "../mixins/cursorMixin";

import sanity from "../client";
import { mapActions, mapState } from "vuex";
import { getContextImageUrl, preloadImages } from "../core/images/contextImage";
import { WORK_QUERY, NEXT_WORK_QUERY } from "../core/queries/workQuery";

export default {
    name: "Work",
    mixins: [locomotiveScrollMixin, cursorMixin],
    components: {
        "work-hero": WorkHeroVue,
        "work-timer": WorkTimerVue,
        "work-intro": WorkIntroVue,
        "work-column": WorkColumnVue,
        "work-composition": WorkCompositionVue,
        "work-text": WorkTextVue,
        "work-carousel": WorkCarouselVue,
        "work-number": WorkNumberVue,
        "work-rail": WorkRailVue,
        "work-video-trigger": WorkVideoTriggerVue,
        "work-video-modal": WorkVideoModalVue,
        "work-credits": WorkCreditsVue,
        "work-footer": WorkFooterVue,
        "work-image": WorkImageVue
    },
    data() {
        return {
            work: null,
            nextWork: null
        };
    },
    computed: {
        ...mapState("main", ["isPageEntering", "pageLeavingDuration"]),
    },
    beforeRouteLeave(to, from, next) {
        this.onPageLeaving(next)
    },
    beforeRouteUpdate(to, from, next) {
        this.onPageLeaving(next)
    },
    watch: {
        isPageEntering: "onPageEntering",
    },
    created() {
        this.fetchData();
    },
    methods: {
        ...mapActions("main", ["setDataLoaded", "setImagesLoaded"]),

        async fetchData() {
            const params = { slug: this.$route.params.slug };

            // Fetch data
            await sanity.fetch(WORK_QUERY, params).then(
                (works) => {
                    this.work = works[0];
                },
                (error) => {
                    console.error(error);
                }
            );

            // Fetch data
            await sanity.fetch(NEXT_WORK_QUERY, params).then(
                (otherWorks) => {
                    const nextWork = otherWorks[Math.floor(Math.random() * otherWorks.length)]
                    this.nextWork = nextWork

                    console.log(otherWorks)

                    this.$nextTick(() => {
                        this.setDataLoaded(true);
                    });
                },
                (error) => {
                    console.error(error);
                }
            );

            // Preload Images
            preloadImages(
                [getContextImageUrl(this.work.cover, "cover")],
                () => {
                    this.setImagesLoaded(true);
                }
            );
        },

        onPageEntering(isEntering) {
            if (!isEntering) return
            console.log("ENTERING ANIMATION HERE")
        },

        onPageLeaving(next) {
            this.setImagesLoaded(false);
            this.setDataLoaded(false);

            // After animation complete
            setTimeout(() => {
                next();
            }, this.pageLeavingDuration)
        }
    },
};
</script>