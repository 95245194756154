<template>
    <div class="c-work-carousel" >
        <div class="o-container || o-padding -padding-wide-top">
            <div class="o-grid -col-12 -gutter">
                <div class=" u-gc-1/13 u-gc-3/13@from-small">
                    <ul class="c-work-carousel_inner">
                        <li class="c-work-carousel_item" :class="{'is-active': imageIndex === activeIndex}" v-for="(imageSrc, imageIndex) in imagesSrcs" :key="imageIndex">
                            <div class="c-figure">
                                <div class="c-figure_inner || o-lazy">
                                    <img 
                                        class="c-figure_image || o-lazy_image"
                                        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 3'%3E%3C/svg%3E"
                                        :data-src="imageSrc" 
                                        alt="tmp"
                                        data-scroll
                                        data-scroll-call="lazyLoad"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Raf from "quark-raf";
import { getContextImageUrl } from "../../core/images/contextImage";

export default {
    name: "WorkCarousel",
    props: {
        images: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            imagesSrcs: [],
            activeIndex: 0,
        };
    },
    created() {
        this.isPlaying = false;
        this.delay = 4000;
        this.startTime = new Date().getTime();

        this.onUpdateBind = this.onUpdate.bind(this)

        // Srcs
        this.images.forEach((image) => {
            this.imagesSrcs.push(getContextImageUrl(image, 'carousel'))
        })
    },
    mounted() {
        this.play()
    },
    methods: {
        play() {
            if (this.isPlaying) return;

            this.isPlaying = true;

            Raf.add(this.onUpdateBind);
        },

        stop() {
            if (!this.isPlaying) return;

            this.isPlaying = false;

            Raf.remove(this.onUpdateBind);
        },

        onUpdate() {
            const current = new Date().getTime()
            const delta = current - this.startTime;
                
            if(delta >= this.delay) {
                this.activeIndex = (this.activeIndex + 1) % this.images.length;
                this.startTime = new Date().getTime();
            }
        }
    },
};
</script>