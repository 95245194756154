<template>
    <div class="c-work-image" >
        <div class="o-container || o-padding -padding-wide-top">
            <div class="o-grid -col-12 -gutter">
                <div class=" u-gc-1/13 u-gc-3/13@from-small">
                    <div class="c-figure">
                        <div class="c-figure_inner || o-lazy">
                            <img 
                                class="c-figure_image || o-lazy_image"
                                src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 3'%3E%3C/svg%3E"
                                :data-src="imageSrc" 
                                alt="tmp"
                                data-scroll
                                data-scroll-call="lazyLoad"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getContextImageUrl } from "../../core/images/contextImage";

export default {
    name: "WorkImage",
    props: {
        image: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            imageSrc: ''
        };
    },
    created() {
        this.imageSrc = getContextImageUrl(this.image, 'image')
    },
};
</script>