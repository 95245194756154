<template>
    <div class="c-work-video-trigger">
        <div class="o-container || o-padding -padding-wide-top">
            <div
                ref="area"
                class="c-work-video-trigger_inner"
                @mouseenter.passive="onMouseEnter"
                @mousemove="onMouseMove"
                @mouseleave.passive="onMouseLeave"
            >
                <div class="c-figure">
                    <div class="c-figure_inner || o-lazy">
                        <img
                            class="c-figure_image || o-lazy_image"
                            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 3'%3E%3C/svg%3E"
                            :data-src="imageSrc"
                            alt="tmp"
                            data-scroll
                            data-scroll-call="lazyLoad"
                        />
                    </div>
                </div>
                <div class="c-work-video-trigger_mouse" ref="mouseArea">
                    <span
                        class="c-work-video-trigger_follow"
                        ref="translatedItem"
                    >
                        <svg
                            class="svg-play"
                            role="img"
                            aria-hidden="true"
                            focusable="false"
                        >
                            <use xlink:href="@/assets/svg/play.svg"></use>
                        </svg>
                    </span>
                </div>
                <button
                    class="c-work-video-trigger_button"
                    @click="onClick"
                    data-cursor="hidden"
                >
                    <span class="u-screen-reader-text">Play Video</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Raf from "quark-raf";
import { mapState, mapActions } from "vuex";
import bezier from "bezier-easing";

// Easing function
const easing = bezier(0.38, 0.005, 0.215, 1);

import { getContextImageUrl } from "../../core/images/contextImage";

export default {
    name: "WorkVideoTrigger",
    components: {},
    props: {
        vimeoId: {
            type: String,
            required: true,
        },
        cover: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            imageSrc: "",
        };
    },
    computed: {
        ...mapState("main", ["isFontsLoaded"]),
        ...mapState("metrics", ["width"]),
        ...mapState("scroll", ["currentScroll", "isSmooth"]),
    },
    watch: {
        isFontsLoaded: "onFontsLoaded",
        width: "onResize",
        currentScroll: "onCurrentScrollChange",
    },
    created() {
        this.area = {
            startX: 0,
            startY: 0,
        };

        this.mouseArea = {
            startX: 0,
            startY: 0,
        };

        this.translate = {
            x: 0,
            y: 0,
            oldX: 0,
            oldY: 0,
            smoothX: 0,
            smoothY: 0,
            lerp: 0.08,
        };

        this.isPlaying = false;

        // Prepare cover
        if (this.cover) {
            this.imageSrc = getContextImageUrl(this.cover, "cover");
        }

        this.onUpdateBind = this.onUpdate.bind(this);
    },
    mounted() {
        this.computeAreaMetricsX();
        this.computeAreaMetricsY();
        this.computeMouseAreaMetricsX();
        this.computeMouseAreaMetricsY();
        this.play();
    },
    beforeUnmount() {
        this.stop();
    },
    methods: {
        ...mapActions("main", ["setActiveVimeoId"]),

        // Events
        onFontsLoaded() {
            this.computeAreaMetricsX();
            this.computeAreaMetricsY();
            this.computeMouseAreaMetricsX();
            this.computeMouseAreaMetricsY();
        },

        onResize() {
            this.computeAreaMetricsX();
            this.computeAreaMetricsY();
            this.computeMouseAreaMetricsX();
            this.computeMouseAreaMetricsY();
        },

        onCurrentScrollChange() {
            this.computeAreaMetricsY();
            this.computeMouseAreaMetricsY();
        },

        onClick() {
            this.setActiveVimeoId(this.vimeoId);
        },

        onMouseEnter() {
            this.isAnimating = false;
        },

        onMouseMove(e) {
            const pageX = e.pageX;
            const pageY = e.pageY;

            const currentX = pageX - this.area.startX;
            let currentY;
            if (this.isSmooth) {
                currentY = pageY - (this.area.startY - this.currentScroll);
            } else {
                currentY = pageY - this.area.startY;
            }

            this.translate.x = currentX - this.mouseArea.startX;
            this.translate.y = currentY - this.mouseArea.startY;
        },

        onMouseLeave() {
            this.translate.x = 0;
            this.translate.y = 0;

            this.startTime = performance.now();
            this.translate.oldX = this.translate.smoothX;
            this.translate.oldY = this.translate.smoothY;
            this.isAnimating = true;
        },

        onUpdate() {
            // Back its place
            if (this.isAnimating) {
                const duration = 400;
                const dateNow = performance.now();
                const elapsed = dateNow - this.startTime;
                const progress = easing(Math.min(elapsed / duration, 1));
                const animatedX =
                    (1 - progress) * this.translate.oldX + progress * 0;
                const animatedY =
                    (1 - progress) * this.translate.oldY + progress * 0;

                if (progress >= 1) {
                    this.translate.smoothX = this.translate.x = 0;
                    this.translate.smoothY = this.translate.y = 0;
                    this.translateItem(animatedX, animatedY);
                    this.isAnimating = false;
                } else {
                    this.translate.smoothX = this.translate.x = animatedX;
                    this.translate.smoothY = this.translate.y = animatedY;
                    this.translateItem(animatedX, animatedY);
                }

                // Follow mouse
            } else {
                this.translate.smoothX +=
                    (this.translate.x - this.translate.smoothX) *
                    this.translate.lerp;
                this.translate.smoothX =
                    ((100 * (this.translate.smoothX + 0.01)) | 0) / 100;

                this.translate.smoothY +=
                    (this.translate.y - this.translate.smoothY) *
                    this.translate.lerp;
                this.translate.smoothY =
                    ((100 * (this.translate.smoothY + 0.01)) | 0) / 100;

                this.translateItem(
                    this.translate.smoothX,
                    this.translate.smoothY
                );
            }
        },

        computeAreaMetricsX() {
            this.area.startX = this.$refs.area.offsetLeft;
        },

        computeAreaMetricsY() {
            this.area.startY = this.$refs.area.offsetTop;
        },

        computeMouseAreaMetricsX() {
            this.mouseArea.startX =
                this.$refs.mouseArea.offsetLeft +
                this.$refs.mouseArea.offsetWidth / 2;
        },

        computeMouseAreaMetricsY() {
            this.mouseArea.startY =
                this.$refs.mouseArea.offsetTop +
                this.$refs.mouseArea.offsetHeight / 2;
        },

        translateItem(x, y) {
            this.$refs.translatedItem.style.transform = `translate3d(${x}px, ${y}px, 0)`;
        },

        play() {
            if (this.isPlaying) return;
            this.isPlaying = true;
            Raf.add(this.onUpdateBind);
        },

        stop() {
            if (!this.isPlaying) return;
            this.isPlaying = false;
            Raf.remove(this.onUpdateBind);
        },
    },
};
</script>