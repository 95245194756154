<template>
    <div class="c-work-text" >
        <div class="o-container || o-padding -padding-wide-top">
            <p v-if="text" class="c-work-text_content || c-text-work -text" v-html="text" />
        </div>
    </div>
</template>

<script>
export default {
    name: "WorkText",
    props: {
        text: {
            type: String,
            required: true
        }
    }
};
</script>