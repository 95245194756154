<template>
    <div class="c-work-footer" >
        <div class="c-work-footer_inner">
            <div class="c-work-footer_top">
                <div class="c-next">
                    <div class="o-container">
                        <div class="o-grid -col-12 -gutter -bottom-items">
                            <div class="u-gc-1/7">
                                <p class="c-next_label || c-text-work -next">Next project</p>
                                <h2 class="c-next_title || c-heading-work -next-title">{{ title }}</h2>
                                <p class="c-next_index">
                                    <span class="c-text-work -index-italic"><i>F</i></span>
                                    <span class="c-text-work -index">-{{ uPad(index, 10) }}</span
                                    >
                                </p>
                                <router-link
                                    class="c-next_link"
                                    :to="'/work/' + slug"
                                    :aria-label="'Go to ' + title"
                                    data-cursor='hover'
                                >
                                    <span class="u-screen-reader-text">
                                        Go to {{ title }}
                                    </span>
                                </router-link>
                            </div>
                            <div class="u-gc-8/13">
                                <div class="c-next_cover">
                                    <div class="c-figure">
                                        <div class="c-figure_inner || o-lazy">
                                            <img 
                                                class="c-figure_image || o-lazy_image" 
                                                src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 3'%3E%3C/svg%3E"
                                                :data-src="imageSrc"
                                                alt="tmp"
                                                data-scroll
                                                data-scroll-call="lazyLoad"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-work-footer_bottom">
                <div class="o-container">
                    <div class="c-work-footer_bottom_inner">
                        <p class="c-work-footer_credits || c-text-work -note || u-color-grey-dark">© Mu Design Lab. All rights reserved</p>
                        <router-link
                            class="c-work-footer_contact || c-link -icon"
                            to="/about"
                            aria-label="about"
                            data-cursor='hover'
                        >
                            <span class="c-link_label">
                                I want to collaborate on a project
                            </span>
                            <span class="c-link_icon">
                                <svg
                                    class="svg-arrow-left"
                                    role="img"
                                    aria-hidden="true"
                                    focusable="false"
                                >
                                    <use xlink:href="@/assets/svg/arrow-left.svg"></use>
                                </svg>
                            </span>
                    </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getContextImageUrl } from "../../core/images/contextImage";
export default {
    name: "WorkFooter",
    props: {
        title: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            default: 0,
        },
        cover: {
            type: Object,
            default: () => {},
        },
        slug: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            imageSrc: ''
        };
    },
    created() {
        this.imageSrc = getContextImageUrl(this.cover, 'footer')
    },
    mounted() {
        this.$nextTick(() => {
            this.forceScrollUpdate()
        }) 
    },
    methods: {
        ...mapActions('main', ['forceScrollUpdate']),

        uPad(number, base) {
            return number < base ? "0" + number.toString() : number.toString();
        },
    },
};
</script>