<template>
    <div class="c-work-timer" data-scroll data-scroll-offset="50%">
        <div class="o-container || o-padding -padding-large-top">
            <div class="o-grid -col-12 -gutter">
                <div class="u-gc-1/13 u-gc-2/12@from-small">
                    <p class="c-work-timer_title || c-heading-work -timer-title">Launch in</p>
                    <div class="c-work-timer_list">
                        <p class="c-work-timer_item -years" ref="item">

                            <span>
                                <span class="c-work-timer_number || c-text-work -timer" aria-hidden="true">
                                    <span v-for="(number,index) in prevYears" :key="index" :style="{ '--number-index': index + 0 }">
                                        {{ number }}
                                    </span>
                                </span>
                                <span class="c-work-timer_number || c-text-work -timer">
                                    <span v-for="(number,index) in targetYears" :key="index" :style="{ '--number-index': index + 0 }">
                                        {{ number }}
                                    </span>
                                </span>
                                <span class="c-work-timer_key || c-text-work -timer-item || u-color-grey">
                                    Years
                                </span>
                            </span>
                            
                        </p>
                        <p class="c-work-timer_separator || u-hide-to-small">
                            <svg class="svg-timer-separator" role="img" aria-hidden="true" focusable="false">
                                <use xlink:href="@/assets/svg/timer-separator.svg"></use>
                            </svg>
                        </p>
                        <p class="c-work-timer_item -months" ref="item">
                            <span>
                                <span class="c-work-timer_number || c-text-work -timer" aria-hidden="true">
                                    <span v-for="(number,index) in prevMonths" :key="index" :style="{ '--number-index': index + 1 }">
                                        {{ number }}
                                    </span>
                                </span>
                                <span class="c-work-timer_number || c-text-work -timer">
                                    <span v-for="(number,index) in targetMonths" :key="index" :style="{ '--number-index': index + 1 }">
                                        {{ number }}
                                    </span>
                                </span>
                                <span class="c-work-timer_key || c-text-work -timer-item || u-color-grey">
                                    Months
                                </span>
                            </span>
                        </p>
                        <p class="c-work-timer_separator || u-hide-to-small">
                            <svg class="svg-timer-separator" role="img" aria-hidden="true" focusable="false">
                                <use xlink:href="@/assets/svg/timer-separator.svg"></use>
                            </svg>
                        </p>
                        <p class="c-work-timer_item -days" ref="item">
                            <span>
                                <span class="c-work-timer_number || c-text-work -timer" aria-hidden="true">
                                    <span v-for="(number,index) in prevDays" :key="index" :style="{ '--number-index': index + 2 }">
                                        {{ number }}
                                    </span>
                                </span>
                                <span class="c-work-timer_number || c-text-work -timer">
                                    <span v-for="(number,index) in targetDays" :key="index" :style="{ '--number-index': index + 2 }">
                                        {{ number }}
                                    </span>
                                </span>
                                <span class="c-work-timer_key || c-text-work -timer-item || u-color-grey">
                                    Days
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "WorkTimer",
    props: {
        date: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            years: 0,
            months: 0,
            days: 0,
        };
    },
    computed: {
        ...mapState("main", ["isFontsLoaded"]),
        ...mapState("metrics", ["width"]),

        targetYears() {
            return this.years.split("");
        },
        targetMonths() {
            return this.months.split("");
        },
        targetDays() {
            return this.days.split("");
        },
        prevYears() {
            return this.targetYears.map((numberStr) => {
                const number = parseInt(numberStr, 10);
                return number + 1 > 9 ? 0 : number + 1;
            });
        },
        prevMonths() {
            return this.targetMonths.map((numberStr) => {
                const number = parseInt(numberStr, 10);
                return number + 1 > 9 ? 0 : number + 1;
            });
        },
        prevDays() {
            return this.targetDays.map((numberStr) => {
                const number = parseInt(numberStr, 10);
                return number + 1 > 9 ? 0 : number + 1;
            });
        },
    },

    watch: {
        isFontsLoaded: "onFontsLoaded",
        width: "onResize",
    },

    created() {
        this.computeDate();
    },

    mounted() {
        if (this.isFontsLoaded) {
            this.onFontsLoaded()
        }
    },

    methods: {
        computeDate() {
            const formatDate = this.date.split("T");

            if (!formatDate.length) return;

            const targetDate = new Date(this.date).getTime();
            const currentDate = new Date().getTime();
            const secondsLeft = (targetDate - currentDate) / 1000;

            let days = 0;
            let months = 0;
            let years = 0;

            const countdown = this.countdown(
                new Date(formatDate[0] + " 23:59")
            );

            if (secondsLeft > 0 && countdown.years > -1) {
                years = countdown.years;
                months = countdown.months;
                days = countdown.days;
            }

            this.years = this.uPad(years, 10);
            this.months = this.uPad(months, 10);
            this.days = this.uPad(days, 10);
        },

        countdown(date) {
            var startDate = new Date(date);
            var diffDate = new Date(startDate - new Date());

            return {
                years: diffDate.toISOString().slice(0, 4) - 1970,
                months: diffDate.getMonth(),
                days: diffDate.getDate() - 1,
            };
        },

        uPad(number, base) {
            return number < base ? "0" + number.toString() : number.toString();
        },

        onFontsLoaded() {
            this.computeNumberWidth()
        },

        onResize() {
            this.computeNumberWidth()
        },

        computeNumberWidth() {
            const $items = this.$el.querySelectorAll('.c-work-timer_item')
            let itemsIndex = 0
            
            while (itemsIndex < $items.length) {
                const $item = $items[itemsIndex]
                $item.style.setProperty('--number-width', `auto`)

                this.$nextTick(() => {
                    
                    const $numbers = $item.querySelectorAll('.c-work-timer_number')
                    let numbersIndex = 0
    
                    const numbersWidths = []
    
                    while (numbersIndex < $numbers.length) {
                        const $number = $numbers[numbersIndex]
                        const numberWidth = $number.offsetWidth
                        numbersWidths.push(numberWidth)
                        numbersIndex++
                    }
    
                    const numberWidth = Math.max(...numbersWidths)
                    $item.style.setProperty('--number-width', `${numberWidth}px`)

                })
                

                itemsIndex++
            }
        }
    },
};
</script>