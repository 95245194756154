<template>
    <div class="c-list">
        <h2 class="c-list_title || c-heading-work -list-title">{{ title }}</h2>
        <ul class="c-list_list">
            <li class="c-list_item" v-for="(item, itemIndex) in items" :key="itemIndex">
                <div class="c-list_item_inner">
                    <h3 class="c-text-work -list-title">{{ item.key }}</h3>
                    <p class="c-text-work -list-label">{{ item.value }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "List",
    props: {
        title: {
            type: String,
            default: 'Introduction'
        },
        items: {
            type: Array,
            default: () => []
        }
    }
};
</script>