<template>
    <div class="c-work-video-modal" :class="{ 'is-active': isActive }">
        <!-- <button
            type="button"
            class="c-button -white || c-work-video-modal_close"
            data-label="Close"
            @click="close"
        >
            <span class="c-button_label">Close</span>
        </button> -->

        <div class="c-work-video-modal_content">
            <div class="c-work-video-modal_inner">
                <iframe
                    v-if="iframeSrc"
                    ref="iframe"
                    :src="iframeSrc"
                    frameborder="0"
                    allow="fullscreen"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
        <div class="c-work-video-modal_background" @click="close"></div>
    </div>
</template>

<script>
import Player from "@vimeo/player";
import { mapState, mapActions } from "vuex";
export default {
    name: "WorkVideoModal",
    components: {},
    props: {
        vimeoId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isActive: false,
        };
    },
    computed: {
        ...mapState("main", ["activeVimeoId"]),
        ...mapState("metrics", ["width"]),

        iframeSrc() {
            return `https://player.vimeo.com/video/${this.vimeoId}`;
        },
    },
    watch: {
        activeVimeoId: "onVimeoIdChange",
        width: "onResize",
    },
    created() {
        this.closeBind = (e) => {
            if (e.key === "Escape") {
                this.close();
            }
        };
        this.onEndedBind = this.onEnded.bind(this);
    },
    mounted() {
        this.bindEvents();

        this.$nextTick(() => {
            this.initVimeo();
        });
    },
    beforeUnmount() {
        this.unbindEvents();
        this.player?.off("ended", this.onEndedBind);
        this.player?.destroy();
    },
    methods: {
        ...mapActions("main", ["setActiveVimeoId"]),
        ...mapActions("cursor", ["setCursorState"]),

        bindEvents() {
            document.addEventListener("keyup", this.closeBind);
        },

        unbindEvents() {
            document.removeEventListener("keyup", this.closeBind);
        },

        onVimeoIdChange(vimeoId) {
            if (this.vimeoId === vimeoId) {
                console.log("vimeo", vimeoId);
                this.open();
            }
        },

        onEnded() {
            setTimeout(() => {
                this.close();
            }, 100);
        },

        onResize() {
            this.computeMaxWidth();
        },

        open() {
            this.isActive = true;
            this.player?.play?.();
            setTimeout(() => {
                this.setCursorState('hidden')
            }, 400)
        },

        close() {
            this.isActive = false;
            this.setActiveVimeoId("");
            this.setCursorState('default')

            // Pause
            this.player?.pause?.().then(() => {
                // Then reset to start
                setTimeout(() => {
                    this.player?.setCurrentTime?.(0);
                }, 200);
            });
        },

        initVimeo() {
            this.player = new Player(this.$refs.iframe);
            this.player.on("ended", this.onEndedBind);

            this.$nextTick(() => {
                this.computeMaxWidth()
            })
        },

        computeMaxWidth() {
            const maxHeight = window.innerHeight - 200;
            const maxWidth = Math.round((16 / 9) * maxHeight);
            this.$el.style.setProperty("--max-width", `${maxWidth}px`);
        },
    },
};
</script>